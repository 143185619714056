body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: fixed;
  background-color: #eceff1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiPickersCalendarHeader-iconButton {
  color: #607d8b !important;
  background-color: transparent !important;
  border: 1px solid #90a4ae !important;
}

.MuiPickersCalendarHeader-iconButton:hover {
  color: #607d8b !important;
  background-color: #eceff1 !important;
  border: 1px solid #607d8b !important;
}
